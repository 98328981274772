<script lang="ts">
  import Logo from '@components/SVGs/Logo.svelte';
  import { APP_ROUTES } from '@core/const/app-routes.const';
  import { urlBuilder } from '@core/utils/url-builder';
  import { onMount } from 'svelte';

  let homeUrl = urlBuilder(APP_ROUTES.careers.index.original);

  let allPositionsUrl = urlBuilder(APP_ROUTES.careers.index.original) + '#all-positions';
  let isOfferPage = false;

  onMount(() => {
    isOfferPage = window.location.pathname.includes('/offer');
  });
</script>

<header class="w-full flex justify-center py-6 lg:py-8 relative z-10 bg-light-gray">
  <div class="careers-wrapper flex">
    <a href={homeUrl} class="contents" title="Flip Shop - Careers">
      <Logo css="max-w-[120px] fill-white" />
    </a>

    <a
      href={allPositionsUrl}
      class="c-btn ml-auto"
      class:btn-black-carbon={!isOfferPage}
      class:btn-black={isOfferPage}>open positions</a
    >
  </div>
</header>
